import React, { useState, useEffect } from "react"

import BlockContent from "../../blockContent/blockContent.js"
import Toc from "../../blockContent/toc/toc"

const BlogRichtext = ({ blocks, enableAutoToc = false, tocTitle }) => {
  const [headings, setHeadings] = useState([])

  useEffect(() => {
    setHeadings(blocks?.filter(item => /^h\d/.test(item.style)))
  }, [blocks])

  return (
    <>
      {enableAutoToc ? <Toc headings={headings} title={tocTitle} /> : null}
      <BlockContent blocks={blocks} />
    </>
  )
}
export default BlogRichtext
