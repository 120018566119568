import React from "react"
import Img from "gatsby-image"

import BlockContent from "../../blockContent/blockContent"

import styles from "./blogAuthor.module.scss"

const BlogAuthor = ({ about, image, name }) => (
  <div className={styles.blogAuthor}>
    <Img className={styles.image} fluid={image} alt={name} />
    <div>
      <h2 className={styles.title}>About the author: {name}</h2>
      <BlockContent blocks={about} />
    </div>
  </div>
)

export default BlogAuthor
