import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import * as Layout2Col from "../components/layout/layout2col/layout2col"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Seo from "../components/seo/seo"

import BlogRichtext from "../components/blog/blogRichText/blogRichText"
import BlogAuthor from "../components/blog/blogAuthor/blogAuthor"
import BlogCategories from "../components/blog/blogCategories/blogCategories"
import Thumbnail from "../components/shared/thumbnail/thumbnail"
import BlogSubscribeForm from "../components/blog/blogSubscribeForm/blogSubscribeForm"
import Cta from "../components/cta/cta"
import CtaForm from "../components/ctaForm/ctaForm"

// The order of the elements: article, aside and aside for mobile is being changed by flex
// - this is done for SEO resons as we always want to have TOC on the top just after Header, but that's misaligned with the designs
// article is first in the markup, but is displayed as second
// aside for mobile is to move part of it's contents to be displayed after blog post

const BlogPost = ({ data, pageContext }) => {
  const { content, seo, _updatedAt } = data.sanityBlogPost.tabs
  const steps = [
    {
      link: `blog`,
      title: "Blog",
    },
    {
      link: `blog/${content.category.slug.current}`,
      title: content.category.title,
    },
  ]

  return (
    <Layout>
      <Seo
        isBlogPost={true}
        title={content.title}
        datePublished={content.seoPublishDate}
        dateModified={_updatedAt}
        author={content.author?.name}
        path={pageContext.path}
        image={content.featuredImage?.asset.url}
        breadcrumbs={steps}
        {...seo}
      />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={content._rawHero[0].children} subtitle={content.subtitle} small={true} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          <article>
            <BlogRichtext
              blocks={content._rawRichText}
              enableAutoToc={content.enableAutoToc}
              tocTitle={content.tocTitle}
            />
            {content.hideAuthor ? null : (
              <BlogAuthor
                name={content.author?.name}
                about={content.author?.about}
                image={content.author?.image?.asset.fluid}
              />
            )}
          </article>
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <Thumbnail
            author={content.hideAuthor ? null : content.author?.name}
            date={content.publishDate}
            image={content.featuredImage?.asset.fluid}
          />
          <BlogCategories hideOnMobile />
          <BlogSubscribeForm hideOnMobile />
        </Layout2Col.Sidebar>
        <Layout2Col.MobileSidebar>
          <BlogCategories hideOnDesktop />
          <BlogSubscribeForm hideOnDesktop />
        </Layout2Col.MobileSidebar>
      </Layout2Col.Wrapper>
      {content.cta?.__typename === "SanityCtaForm" ? (
        <CtaForm {...content.cta} background="blue" />
      ) : (
        <Cta {...content.cta} background="blue" />
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityBlogPost(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt
      tabs {
        content {
          title
          _rawHero
          subtitle
          publishDate(formatString: "MMM DD, YYYY")
          seoPublishDate: publishDate
          featuredImage {
            asset {
              url
              fluid(maxWidth: 580) {
                ...GatsbySanityImageFluid
              }
            }
          }
          category {
            slug {
              current
            }
            title
          }
          enableAutoToc
          tocTitle
          hideAuthor
          author {
            ... on SanityBlogGuestAuthor {
              id
              name
              about: _rawAbout
              image {
                asset {
                  fluid(maxWidth: 150) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityTeam {
              id
              name
              about: _rawAbout
              image {
                asset {
                  fluid(maxWidth: 150) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          _rawRichText(resolveReferences: { maxDepth: 20 })
          cta {
            ... on SanityCta {
              ...ctaFragment
            }
            ... on SanityCtaForm {
              ...ctaFormFragment
            }
          }
        }
      }
      ...seoFragment
    }
  }
`

export default BlogPost
